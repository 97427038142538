<template>
  <div>
    <main class="flex-auto pb-20">
      <div class="overflow-hidden py-16 lg:pb-20">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
            <div class="relative z-10 mx-auto max-w-2xl lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6 space-y-6">
              <h1 class="text-4xl font-semibold text-gray-900">
                {{ $t('page.index.title', {x: platforms_supported_count}) }}
              </h1>

              <h2 class="text-lg text-gray-600">
                {{ $t('page.index.subtitle') }}
              </h2>

              <!--              <div class="flex justify-center gap-x-6 gap-y-4">
                              <a
                                  :href="$global.how_does_it_work_video"
                                  class="flex items-center p-2 space-x-2 rounded-lg border text-sm outline-2 border-gray-300 text-gray-700 hover:bg-red-500 hover:border-red-500 hover:text-white">
                                <icon name="material-symbols:play-circle-rounded" size="30"/>

                                <span>
                                  {{ $t('page.index.how_does_it_work') }}
                                </span>
                              </a>
                            </div>-->
            </div>

            <div class="relative mt-10 sm:mt-20 lg:col-span-5 lg:row-span-2 lg:mt-0 xl:col-span-6">
              <div
                  class="absolute left-1/2 top-4 h-[1026px] w-[1026px] -translate-x-1/3 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:-top-14 xl:ml-0">
                <svg aria-hidden="true" class="absolute inset-0 h-full w-full animate-spin-slow" fill="none"
                     viewBox="0 0 1026 1026">
                  <path d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z"
                        stroke="#D4D4D4" stroke-opacity="0.7"></path>
                  <path d="M513 1025C230.23 1025 1 795.77 1 513" stroke="url(#:S2:-gradient-1)"
                        stroke-linecap="round"></path>
                  <defs>
                    <linearGradient id=":S2:-gradient-1" gradientUnits="userSpaceOnUse" x1="1" x2="1" y1="513"
                                    y2="1025">
                      <stop stop-color="#06b6d4"></stop>
                      <stop offset="1" stop-color="#06b6d4" stop-opacity="0"></stop>
                    </linearGradient>
                  </defs>
                </svg>
                <svg aria-hidden="true" class="absolute inset-0 h-full w-full animate-spin-reverse-slower" fill="none"
                     viewBox="0 0 1026 1026">
                  <path
                      d="M913 513c0 220.914-179.086 400-400 400S113 733.914 113 513s179.086-400 400-400 400 179.086 400 400Z"
                      stroke="#D4D4D4" stroke-opacity="0.7"></path>
                  <path d="M913 513c0 220.914-179.086 400-400 400" stroke="url(#:S2:-gradient-2)"
                        stroke-linecap="round"></path>
                  <defs>
                    <linearGradient id=":S2:-gradient-2" gradientUnits="userSpaceOnUse" x1="913" x2="913" y1="513"
                                    y2="913">
                      <stop stop-color="#06b6d4"></stop>
                      <stop offset="1" stop-color="#06b6d4" stop-opacity="0"></stop>
                    </linearGradient>
                  </defs>
                </svg>
              </div>

              <div
                  class="-mx-4 h-[448px] px-9 [mask-image:linear-gradient(to_bottom,white_60%,transparent)] sm:mx-0 lg:absolute lg:-inset-x-10 lg:-bottom-20 lg:-top-10 lg:h-auto lg:px-0 lg:pt-10 xl:-bottom-32">
                <div class="relative aspect-[366/729] mx-auto max-w-[366px]">
                  <svg aria-hidden="true" class="pointer-events-none absolute inset-0 h-full w-full fill-gray-100"
                       viewBox="0 0 366 729">
                    <path clip-rule="evenodd"
                          d="M300.092 1c41.22 0 63.223 21.99 63.223 63.213V184.94c-.173.184-.329.476-.458.851.188-.282.404-.547.647-.791.844-.073 2.496.257 2.496 2.157V268.719c-.406 2.023-2.605 2.023-2.605 2.023a7.119 7.119 0 0 1-.08-.102v394.462c0 41.213-22.001 63.212-63.223 63.212h-95.074c-.881-.468-2.474-.795-4.323-.838l-33.704-.005-.049.001h-.231l-.141-.001c-2.028 0-3.798.339-4.745.843H66.751c-41.223 0-63.223-21.995-63.223-63.208V287.739c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 284.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-16.289c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 214.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-26.041a2.26 2.26 0 0 0 .093-.236l-.064-.01a3.337 3.337 0 0 1-.72-.12l-.166-.028A2 2 0 0 1 1 135.62v-24.611a2 2 0 0 1 1.671-1.973l.857-.143v-44.68C3.528 22.99 25.53 1 66.75 1h233.341ZM3.952 234.516a5.481 5.481 0 0 0-.229-.278c.082.071.159.163.228.278Zm89.99-206.304A4.213 4.213 0 0 0 89.727 24H56.864C38.714 24 24 38.708 24 56.852v618.296C24 693.292 38.714 708 56.864 708h250.272c18.15 0 32.864-14.708 32.864-32.852V56.852C340 38.708 325.286 24 307.136 24h-32.864a4.212 4.212 0 0 0-4.213 4.212v2.527c0 10.235-8.3 18.532-18.539 18.532H112.48c-10.239 0-18.539-8.297-18.539-18.532v-2.527Z"
                          fill="#F2F2F2"
                          fill-rule="evenodd"></path>
                    <rect fill="#D4D4D4" height="5" rx="2.5" width="56" x="154" y="29"></rect>
                  </svg>
                  <div
                      class="absolute inset-y-[calc(1/729*100%)] left-[calc(7/729*100%)] right-[calc(5/729*100%)] rounded-[calc(58/366*100%)/calc(58/729*100%)] shadow-2xl"></div>
                  <img class="pointer-events-none absolute inset-y-14 inset-x-6 w-fit" decoding="async"
                       height="729"
                       src="/phone-mock-up.png" style="padding-right: 3.14rem" width="316">
                </div>
              </div>
            </div>

            <div class="relative lg:col-span-7 lg:mt-0 xl:col-span-6">
              <p class="text-center text-sm font-semibold text-gray-900 lg:text-left">
                {{ $t('page.index.platforms_we_support', {count: platforms_supported_count}) }}
              </p>

              <div
                  class="mx-auto mt-8 flex max-w-xl flex-wrap justify-center gap-x-10 gap-y-8 lg:mx-0 lg:justify-start">
                <div v-for="supported_platform in supported_platforms" class="flex flex-col items-center space-y-1">
                  <div
                      :class="supported_platform.icon === 'GazetaCelesiAl' ? 'bg-yellow-400 hover:bg-yellow-500' : 'bg-gray-50 hover:bg-gray-100'"
                      class="rounded-full cursor-pointer p-4">
                    <icon
                        :name="supported_platform.icon === 'GazetaCelesiAl' ? 'GazetaCelesiAlGray' : supported_platform.icon"
                        size="30"/>
                  </div>

                  <span class="text-xs text-gray-800">
                    {{ supported_platform.icon === 'GazetaCelesiAl' ? 'gazetacelesi.al' : supported_platform.name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="origin === 'https://thelisting.app'" id="join" class="py-20 sm:py-24">
        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="relative isolate overflow-hidden px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
            <h2 class="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Join the waitlist
            </h2>

            <p class="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-700">
              Get 50% off when we launch. Offer valid for the first 500 realtors.
            </p>

            <form class="mx-auto mt-10 flex flex-col md:flex-row max-w-2xl gap-4" @submit.prevent="joinWaitlist">
              <input id="email" autocomplete="email" class="input w-96" placeholder="Enter your email"
                     required
                     style="border: 1px solid #8f8f8f"
                     type="email">

              <button class="flex-none button-primary px-1.5 w-full md:w-52"
                      type="submit">
                Join the waitlist
              </button>
            </form>

            <svg aria-hidden="true" class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
                 viewBox="0 0 1024 1024">
              <circle cx="512" cy="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.7" r="512"/>
              <defs>
                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641" cx="0" cy="0"
                                gradientTransform="translate(512 512) rotate(90) scale(512)"
                                gradientUnits="userSpaceOnUse"
                                r="1">
                  <stop stop-color="#7775D6"/>
                  <stop offset="1" stop-color="#E935C1" stop-opacity="0"/>
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>

      <div class="flex flex-col space-y-20 sm:space-y-28 pb-20">
        <div id="features" class="bg-gray-900 py-28">
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="mx-auto max-w-2xl sm:text-center">
              <h2 class="text-3xl font-medium tracking-tight text-white">
                {{ $t('page.index.hero_2_title') }}
              </h2>

              <p class="mt-2 text-lg text-white">
                {{ $t('page.index.hero_2_subtitle') }}
              </p>
            </div>

            <div
                class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 text-sm sm:mt-20 sm:grid-cols-2 md:gap-y-10 lg:max-w-none lg:grid-cols-3">
              <nuxt-link v-for="feature in features" :to="feature.navigation"
                         class="rounded-2xl p-8 bg-white cursor-pointer" external="true">
                <div class="flex justify-between items-center">
                  <icon :name="feature.icon" class="text-gray-700" size="36"/>

                  <div class="text-xs font-semibold rounded-3xl bg-green-600 text-white py-2 px-3">
                    {{ $t('page.index.save_x_minutes', {x: feature.minutes_saved}) }}
                  </div>
                </div>

                <h3 class="mt-6 font-semibold">
                  {{ feature.title }}
                </h3>

                <p class="mt-2">
                  {{ $t(feature.description) }}
                </p>
              </nuxt-link>
            </div>
          </div>
        </div>

        <!--        <div v-for="(feature, index) in features" v-if="origin === 'https://agjenti.al'"
                     :id="feature.navigation.split('/#')[1]" class="overflow-hidden">
                  <div v-if="feature.details" class="mx-auto max-w-7xl px-6 lg:px-8">
                    <div
                        class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-7">
                      <div
                          :class="index % 2 ? 'lg:ml-auto lg:pl-4 lg:pt-4 col-span-3' : 'lg:pr-8 place-self-center col-span-3'">
                        <div class="lg:max-w-lg">
                          <h2 class="text-base font-semibold leading-7 text-indigo-600">
                            {{ $t('page.index.save_x_minutes', {x: feature.minutes_saved}) }}
                          </h2>

                          <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            {{ feature.title }}
                          </p>

                          <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                            <div v-for="detail in feature.details" class="relative pl-9 space-x-1">
                              <dt class="inline font-semibold text-gray-900">
                                <icon :name="detail.icon"
                                      class="absolute left-1 top-1 h-5 w-5 text-indigo-600"/>

                                {{ $t(detail.title) }}
                              </dt>
                              <dd class="inline">
                                {{ $t(detail.description) }}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>

                      <div v-if="index % 2" class="flex items-center justify-end lg:order-first col-span-4">
                        <div class="video-container relative w-full h-full max-w-4lg rounded-xl border border-gray-200"
                             @click="playVideo(index)">
                          <video :id="`video-${index}`" :src="feature.video_path"
                                 class="w-full h-full object-contain object-right rounded-xl"
                                 loop
                                 preload="metadata"></video>
                          <div :id="`overlay-${index}`"
                               class="overlay absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center cursor-pointer">
                            <div class="text-gray-800 font-semibold border rounded-lg bg-white px-4 py-2">
                              Play
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-else class="col-span-4">
                        <div class="video-container relative w-full h-full max-w-4lg rounded-xl border border-gray-200"
                             @click="playVideo(index)">
                          <video :id="`video-${index}`" :src="feature.video_path"
                                 class="w-full h-full object-contain object-right rounded-xl"
                                 loop
                                 preload="metadata"></video>
                          <div :id="`overlay-${index}`"
                               class="overlay absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center cursor-pointer">
                            <div class="text-gray-800 font-semibold border rounded-lg bg-white px-4 py-2">
                              Play
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>-->
      </div>

      <div v-if="origin === 'https://agjenti.al'" id="demo" class="relative overflow-hidden bg-gray-900 py-20 sm:py-28">
        <div class="absolute left-20 top-1/2 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2">
          <svg aria-hidden="true" class="animate-spin-slower" fill="none" height="558" viewBox="0 0 558 558"
               width="558">
            <defs>
              <linearGradient id=":S4:" gradientUnits="userSpaceOnUse" x1="79" x2="105" y1="16" y2="237">
                <stop stop-color="#fff"></stop>
                <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
              </linearGradient>
            </defs>
            <path d="M1 279C1 125.465 125.465 1 279 1s278 124.465 278 278-124.465 278-278 278S1 432.535 1 279Z"
                  opacity=".2"
                  stroke="#fff"></path>
            <path d="M1 279C1 125.465 125.465 1 279 1" stroke="url(#:S4:)" stroke-linecap="round"></path>
          </svg>
        </div>

        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
          <div class="mx-auto max-w-md sm:text-center">
            <h2 class="text-3xl font-medium tracking-tight text-white sm:text-4xl">
              {{ $t('page.index.live_demo') }}
            </h2>

            <p class="mt-4 text-lg text-gray-300">
              {{ $t('page.index.live_demo_description') }}
            </p>

            <div
                class="mt-8 flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 space-x-0 sm:space-x-4 justify-center">
              <a
                  class="flex items-center p-2 px-4 rounded-lg transition-colors bg-white text-white text-center hover:bg-yellow-300"
                  href="https://wa.me/355682057077?text=Përshëndetje,%20jam%20i%20interesuar%20për%20agjentin">
                <icon class="text-gray-800 mr-2" name="logos:whatsapp-icon" size="30"/>

                <span class="text-gray-800">
                          WhatsApp
                      </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="pricing"
           class="border-t border-gray-200 bg-gray-100  py-20 sm:py-28">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="mx-auto max-w-2xl text-center">
            <h2 class="text-3xl font-medium tracking-tight text-gray-900">
              {{ $t('page.index.pricing_title') }}
            </h2>

            <p class="mt-2 text-lg text-gray-600">
              {{ $t('page.index.pricing_description') }}
            </p>
          </div>

          <img class="w-full rounded-3xl mt-6" src="/pricing.png"/>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
const {$global} = useNuxtApp()
const {t} = useI18n()

const supported_platforms = ref([])
const platforms_supported_count = ref(null)
const planLimits = ref([])

const origin = computed(() => {
  return process.client ? window.location.origin : ''
})

onMounted(() => {
  getIndexData()
})

let features = []

function getIndexData() {
  $fetch(`${$global.api_url}/api/index`)
      .then(response => {
        const data = response.data

        supported_platforms.value = data.supported_platforms
        platforms_supported_count.value = data.platforms_supported_count

        if (platforms_supported_count.value) {
          features = [
            {
              icon: 'material-symbols:keyboard-alt-rounded',
              title: t('page.index.features.ai_description_title'),
              description: 'page.index.features.ai_description_description',
              navigation: '/#feature-ai',
              minutes_saved: 15,
              video_path: '/landing/ai.mp4',
              details: [
                {
                  icon: 'material-symbols:keyboard-alt-rounded',
                  title: 'page.index.fill_the_form',
                  description: 'page.index.fill_the_form_description',
                },

                {
                  icon: 'material-symbols:auto-detect-voice-rounded',
                  title: 'page.index.choose_the_voice',
                  description: 'page.index.choose_the_voice_description',
                },
                {
                  icon: 'eos-icons:machine-learning',
                  title: 'page.index.machine_generate',
                  description: 'page.index.machine_generate_description',
                }
              ],
            },
            {
              icon: 'material-symbols:stacks-rounded',
              title: t('page.index.features.post_up_to_title', {x: platforms_supported_count.value}),
              description: 'page.index.features.post_up_to_description',
              navigation: '/#feature-post-up-to',
              minutes_saved: 35,
              video_path: '/landing/post-up-to.mp4',
              details: [
                {
                  icon: 'material-symbols:stacks-rounded',
                  title: 'page.index.feature_consistent_posts',
                  description: 'page.index.feature_consistent_posts_description',
                },

                {
                  icon: 'material-symbols:ads-click-rounded',
                  title: 'page.index.feature_post_with_1_click',
                  description: 'page.index.feature_post_with_1_click_description',
                },
                {
                  icon: 'mdi:arrow-decision',
                  title: 'page.index.feature_decide_what_and_where_to_post',
                  description: 'page.index.feature_decide_what_and_where_to_post_description',
                }
              ],
            },
            {
              icon: 'bxs:file-import',
              title: t('page.index.features.import_title'),
              description: 'page.index.features.import_description',
              navigation: '/#feature-import',
              minutes_saved: 10,
              video_path: '/landing/import.mp4',
              details: [
                {
                  icon: 'material-symbols:keyboard-external-input-rounded',
                  title: 'page.index.feature_auto_form_filling',
                  description: 'page.index.feature_auto_form_filling_description',
                },

                {
                  icon: 'mingcute:photo-album-fill',
                  title: 'page.index.feature_auto_image_upload',
                  description: 'page.index.feature_auto_image_upload_description',
                },
                {
                  icon: 'material-symbols:support-agent-rounded',
                  title: 'page.index.feature_support_and_monitoring',
                  description: 'page.index.feature_support_and_monitoring_description',
                }
              ],
            },
            {
              icon: 'material-symbols:directory-sync-rounded',
              title: t('page.index.features.refresh_title'),
              description: 'page.index.features.refresh_description',
              navigation: '/#feature-repost',
              minutes_saved: 21,
              video_path: '/landing/refresh.mp4',
              details: [
                {
                  icon: 'material-symbols:visibility',
                  title: 'page.index.feature_increased_visibility',
                  description: 'page.index.feature_increased_visibility_description',
                },

                {
                  icon: 'fluent:people-community-28-filled',
                  title: 'page.index.feature_extended_reach',
                  description: 'page.index.feature_extended_reach_description',
                },
                {
                  icon: 'material-symbols:favorite-rounded',
                  title: 'page.index.feature_effortless_engagement',
                  description: 'page.index.feature_effortless_engagement_description',
                }
              ],
            },
            {
              icon: 'material-symbols:delete-forever-rounded',
              title: t('page.index.features.delete_title'),
              description: 'page.index.features.delete_description',
              navigation: '/#feature-delete',
              minutes_saved: 14,
              video_path: '/landing/delete.mp4',
              details: [
                {
                  icon: 'mingcute:broom-fill',
                  title: 'page.index.feature_clutter_free',
                  description: 'page.index.feature_clutter_free_description',
                },

                {
                  icon: 'mdi:robot-happy',
                  title: 'page.index.feature_enhanced_user_experience',
                  description: 'page.index.feature_enhanced_user_experience_description',
                },
                {
                  icon: 'zondicons:portfolio',
                  title: 'page.index.feature_optimized_portfolio',
                  description: 'page.index.feature_optimized_portfolio_description',
                }
              ],
            },
            {
              icon: 'material-symbols:playlist-remove-rounded',
              title: t('page.index.features.mark_as_title'),
              description: 'page.index.features.mark_as_description',
              navigation: '/#feature-mark-as-deleted',
              minutes_saved: 18,
              video_path: '/landing/mark-as-deleted.mp4',
              details: [
                {
                  icon: 'material-symbols:clean-hands-rounded',
                  title: 'page.index.feature_clear_updates',
                  description: 'page.index.feature_clear_updates_description',
                },

                {
                  icon: 'icon-park-outline:database-success',
                  title: 'page.index.feature_showcase_success',
                  description: 'page.index.feature_showcase_success_description',
                },
                {
                  icon: 'mdi:comment-text-multiple',
                  title: 'page.index.feature_maintain_engagement',
                  description: 'page.index.feature_maintain_engagement_description',
                }
              ],
            },
            {
              icon: 'tabler:template',
              title: t('page.index.features.design_title'),
              description: 'page.index.features.design_description',
              navigation: '/#feature-design',
              minutes_saved: 30,
              video_path: '/landing/design.mp4',
              details: [
                {
                  icon: 'tabler:template',
                  title: 'page.index.feature_ready_made_templates',
                  description: 'page.index.feature_ready_made_templates_description',
                },

                {
                  icon: 'material-symbols:palette',
                  title: 'page.index.feature_custom_designs',
                  description: 'page.index.feature_custom_designs_description',
                },
                {
                  icon: 'material-symbols:design-services-rounded',
                  title: 'page.index.feature_template_modifications',
                  description: 'page.index.feature_template_modifications_description',
                },
              ],
            },
          ]
        }

        planLimits.value = data.plan_limits
      })
}

function playVideo(index) {
  const video = document.getElementById(`video-${index}`)
  const overlay = document.getElementById(`overlay-${index}`)

  if (video.paused) {
    video.play()
    overlay.style.display = 'none'
  }

  const indexes = features.length - 1

  for (let i = 0; i <= indexes; i++) {
    if (i !== index) {
      const video = document.getElementById(`video-${i}`)
      const overlay = document.getElementById(`overlay-${i}`)

      video.pause()
      overlay.style.display = 'flex'
    }
  }
}

function joinWaitlist() {
  const email = document.getElementById('email').value

  $fetch(`${$global.api_url}/trupi`, {
    method: 'POST',
    body: JSON.stringify({
      phone: email,
      data: 'Waitlist',
    }),
  })
      .then(response => {
        console.log(response)
        if (response.status === 200) {
          alert('You have successfully joined the waitlist')
        } else {
          console.log(response)
          alert('An error occurred. Please try again later')
        }
      })
}
</script>

<style scoped>
.video-container {
  position: relative;
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
